<template>
  <select v-model="selectedIndex" :class="selectClass" @change="onChange">
    <option v-for="(item, index) in source" :value="index" :key="index">
      {{ item.name }}
    </option>
  </select>
</template>

<script>
/** data */
const data = function () {
  return {
    /** 決定要套用的 class，由 prop 傳入 */
    selectClass: this.CssClass,
  };
};

/** select 改變時會改變 */
const selectedIndex = {
  get() {
    return this.value;
  },
  set(value) {
    this.$emit('input', value);
  },
};

/** 當觸發 [change] event 時 */
const onChange = function (value) {
  this.$emit('zip-change', value);
};

export default {
  name: 'city-town-select',
  inject: ['$validator'],
  props: [
    /** v-model 所要綁定的 value */
    'value',
    /** 由外層傳入的 data 做 binding */
    'source',
    /** 由外層傳入的 css class */
    'CssClass',
  ],
  data,
  computed: {
    selectedIndex,
  },
  methods: {
    onChange,
  },
};
</script>
