import axios from 'axios';

const API_REGISTER = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_QUERY_SENAO_REGISTER}`;
const API_SEND = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_QUERY_SENAO_SMS_SEND}`;
const API_QUERY = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_QUERY_SENAO_MEMBERSHIP}`;

const appendXSRF = (url, data) => {
  // xsrf token flag
  data.append('enabledXsrf', true);

  // axios config
  const config = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url,
    data,
  };
  return axios(config);
};

export const fetchRegisterSenaoMembership = (formData) => appendXSRF(API_REGISTER, formData);

export const smsSendSenaoMembership = (formData) => appendXSRF(API_SEND, formData);

export const querySenaoMembership = (mobile) => axios.get(API_QUERY, { params: { mobile } });
