export const count = '帳號';
export const password = '密碼';
export const recipientName = '收件人姓名';
export const payerName = '付款人姓名';
export const mobilePhone = '手機號碼';
export const cellPhone = '手機號碼';
export const phone = '手機號碼';
export const address = '完整地址';
export const city = '縣市';
export const town = '鄉鎮區';
export const store = '神腦門市';
export const localPhoneArea = '市話區碼';
export const localPhone = '市話';
export const localPhoneExt = '市話分機';
export const email = '電子信箱';
export const name = '中文姓名';
export const cardNo = '信用卡號';
export const cardMonth = '有效期限月份';
export const cardYear = '有效期限年份';
export const cardCode = '卡片背面三至四碼';
export const installment = '分期付款期數';
