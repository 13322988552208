import { map, compose, range, reverse } from 'ramda';
import { padLeftZero } from '@/helpers/string';

export const thisYear = new Date().getFullYear();
export const today = new Date().getDay();

export const calculateWeekListMatchingDay = () => (today + 6) % 7;

/** 產生信用卡選擇月份，兩位數補 0 */
export const genMonths = compose(map(padLeftZero(2)), range)(1, 13);

export const genDates = compose(map(padLeftZero(2)), range)(1, 32);

/** 產生信用卡選擇年份 */
export const genYears = compose(range)(thisYear, thisYear + 21);

/** 產生註冊生日選擇年份
 * @return {Array}
 */
export const genBirthYears = reverse(range(thisYear - 80, thisYear));
