import { computed, ref, watch, nextTick } from 'vue';
import { maskName, maskMobile, maskAddress, maskEmail, maskPhone } from '@/helpers/mask';
import { renderState, commitMutation } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import { importOrderData } from '@/helpers/import-order-data';

export function useMask(props) {
  const fetchCreditCard = computed(() => renderState('CardInfo', 'code'));
  const isClickButtons = computed(() => renderState('OrderInfo', 'isClickButtons'));
  const isNotMasked = ref(!isLoginUser());
  const maskedCreditCard = async () => {
    await nextTick();
    isNotMasked.value = false;
  };

  watch(isClickButtons, (newVal) => {
    if (newVal) {
      isNotMasked.value = false;
    }
  });
  watch(fetchCreditCard, (code) => {
    const success = 1;
    if (code === success) {
      maskedCreditCard();
    }
  });
  const maskedName = computed(() => {
    const name = renderState(props.module, 'name');
    return maskName(name);
  });
  const maskedMobile = computed(() => {
    const mobile = renderState(props.module, 'mobilePhone');
    return maskMobile(mobile);
  });
  const maskedEmail = computed(() => {
    const email = renderState(props.module, 'email');
    return maskEmail(email);
  });
  const maskedAddress = computed(() => {
    const address = renderState(props.module, 'address');
    return maskAddress(address);
  });
  const maskedTel = computed(() => {
    const tel = renderState(props.module, 'localPhone');
    return maskPhone(tel);
  });
  const notifyIsMasked = (value) => {
    if (isLoginUser()) {
      commitMutation('OrderInfo', 'isClickButtons', value);
    }
  };
  const handleBlur = () => {
    notifyIsMasked(false);
  };
  const copyBuyerInfo = async (module, orderInfoZip) => {
    notifyIsMasked(true);
    // fix vee-validate console warning
    await nextTick();
    importOrderData(module, orderInfoZip);
  };
  const setIsNotMaskedByValue = (value) => {
    if (!isNotMasked.value && (value === null || value === '')) {
      isNotMasked.value = true;
    }
  };
  return {
    isNotMasked,
    maskedName,
    maskedMobile,
    maskedEmail,
    maskedAddress,
    maskedTel,
    handleBlur,
    copyBuyerInfo,
    setIsNotMaskedByValue,
  };
}
