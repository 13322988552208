const regexes = [
  /^0\d+$/, // 只允許數字
  /^.{1,4}$/, // 長度最大為 4
];

const getMessage = '市話區碼請填寫正確格式(半形數字，不可有空格)';
const validate = (value) => regexes.every((x) => x.test(value));

/** 自訂驗證市話格式 */
export default {
  getMessage,
  validate,
};
