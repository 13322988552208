import { renderState, commitMutations } from '@/helpers/vuex';

// 是外島的郵遞區號
const isIslandZipCode = [
  '209',
  '210',
  '211',
  '212',
  '817',
  '819',
  '880',
  '881',
  '882',
  '883',
  '884',
  '885',
  '890',
  '891',
  '892',
  '893',
  '894',
  '896',
];

const createOrderObj = function (isIslandZip) {
  const obj = {
    name: renderState('OrderInfo', 'name'),
    mobilePhone: renderState('OrderInfo', 'mobilePhone'),
    email: renderState('OrderInfo', 'email'),
    addressCityId: renderState('OrderInfo', 'addressCityId'),
    addressCity: renderState('OrderInfo', 'addressCity'),
    addressTownId: renderState('OrderInfo', 'addressTownId'),
    addressTown: renderState('OrderInfo', 'addressTown'),
    address: isIslandZip ? '' : renderState('OrderInfo', 'address'),
  };
  return obj;
};

// 將訂購人資訊手動匯入覆蓋收件人資訊 ; thisZip 只有在收件人 block 才會傳此參數
export const importOrderData = (module, thisZip) => {
  // check 郵遞區號是否為外島
  const isIslandZip = isIslandZipCode.indexOf(thisZip) > -1;
  commitMutations(module, createOrderObj(isIslandZip));
};
